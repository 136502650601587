/*=============================================
=            profile            =
=============================================*/

/* profile header */
.profile-wrapper {
  padding-bottom: 2rem;
  font-size: 14px;
}
.profile-header {
  display: flex;
  &__image {
    flex-basis: 120px;

    margin-right: 15px;
    img {
      border-radius: 15px;
    }
  }
  &__content {
    flex-basis: calc(100% - 135px);
    .name {
      font-size: 16px;
      font-weight: 700;
    }
  }
  .profile-info {
    display: flex;

    width: 200px;
    max-width: 100%;
    &-block {
      flex-basis: 50%;
      &__value {
        font-size: 12px;
        font-weight: 700;

        color: $black--four;
      }
      &__title {
        font-size: 10px;
        line-height: 1;
      }
    }
  }

  .profile-level {
    &__title {
      font-size: 12px;
      font-weight: 500;

      color: $black--four;
    }

    &__progress {
      height: 4px;

      background-color: rgba($grey--nine, 0.1);
      .progress-bar {
        background-color: $theme-color--default;
      }
    }
  }
}

/* profile body */

.profile-info-table {
  border-radius: 10px;
  background-color: $white;
  .profile-info-block {
    display: flex;
    align-items: space-between;

    padding: 15px 12px;

    color: $black--four;
    border-bottom: 2px solid $grey--seven;

    &:last-child {
      border-bottom: none;
    }
    &__title {
      font-size: 16px;
      font-weight: 500;
      text-align: right;
      flex-basis: 50%;
    }
    &__value {
      font-size: 14px;
      font-weight: 300;

      flex-basis: 50%;

      text-align: left;
      a {
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

.edit-profile-form {
  padding: 40px 20px;

  background-color: $white;
  &__single-field {
    label {
      font-size: 14px;

      margin-bottom: 20px;

      color: $black--two;
    }
    input,
    textarea {
      font-size: 18px;

      display: block;

      width: 100%;
      padding-bottom: 10px;

      color: $black--two;
      border: none;
      border-bottom: 2px solid $grey--eight;

      &:focus {
        border-bottom: 2px solid $theme-color--default;
      }
    }

    ::placeholder {
      opacity: 1; /* Firefox */

      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $grey--four;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $grey--four;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $grey--four;
    }
  }

  &__button {
    font-size: 16px;
    line-height: 1;

    width: 100%;
    padding: 15px 0;

    color: $white;
    border: none;
    background: none;
    background-color: $theme-color--default;
  }
}
.notifications {
  &-wrapper {
    > div:first-child {
      flex-basis: 65%;
    }
    > div:last-child {
      flex-basis: 35%;
    }
  }
  &-button-active {
    border: 1px solid $lupa--red;
    background-color: transparent;
    padding: 0px 8px;
    border-radius: 5px;
    color: $lupa--red;
  }
  &-button-disabled {
    border: 1px solid $theme-color--default;
    background-color: $theme-color--default;
    padding: 0px 8px;
    border-radius: 5px;
    color: white;
  }
  &-title {
    flex-basis: 70% !important;
    align-self: center;
  }
  &-title-red {
    color: $lupa--red;
  }
  &-title span {
    vertical-align: middle;
  }
}
.notification-icon-bell {
  font-size: 1.1rem;
  margin-right: 4px;
}

.profile-prontopago {
  padding: 10px 15px !important;
  > div:first-child {
    flex-basis: 30%;
  }
  > div:last-child {
    flex-basis: 70%;
  }
  &-input {
    &-wrapper {
      position: relative;
    }
    border: 1px solid #777;
    padding: 13px 15px 14px 5px;
    border-radius: 5px;
    width: 3rem;
    height: 2.2rem;
    margin-right: 9px;
    text-align: center;
    font-size: 18px;
    & + span {
      content: "%";
      font-size: 16px;
      right: 14px;
      top: -4px;
      position: absolute;
    }
  }

  &-button-cambiar {
    font-size: 24px;
    line-height: 1rem;
    border: 1px solid $theme-color--default;
    background-color: $theme-color--default;
    border-radius: 5px;
    padding: 5px 6px;
    margin-right: 9px;
    color: white;
  }

  &-button-cancelar {
    font-size: 24px;
    line-height: 1rem;
    border: 1px solid $red;
    background-color: $red;
    border-radius: 5px;
    padding: 5px 6px;
    color: white;
  }
  &-value {
    padding-right: 1rem;
    line-height: 2.3rem;
    color: $theme-color--default; // color de pronto pago de descuento
    font-size: 1.2rem;
  }
}
.profile-iva-disclaimer {
  padding: 0.5rem;
}
/*=====  End of profile  ======*/
