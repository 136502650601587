/*=============================================
=            Button            =
=============================================*/

/*=====  End of Button  ======*/

button:focus {
  outline-style: none !important;
  box-shadow: none !important;
  border-color: black !important;
}

