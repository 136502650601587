.bg-color--grey {
  background-color: rgba($grey, 0.1);
}
.bg-color--grey2 {
  background-color: $grey--ten;
}
.bg-color--grey3 {
  background-color: $grey--twenty;
}
.bg-color--transparent {
  background-color: transparent;
}

.bg-color--gradient {
  background-image: linear-gradient(to bottom, $white 0%, $white 100%);
}
.bg-lupa-red {
  background-color: $lupa--red-soft;
}
.bg-lupa-green {
  background-color: $lupa--green-soft;
}
.bg-lupa-yellow {
  background-color: $lupa--yellow;
  color: #333 !important;
}
.bg-lupa-orange {
  background-color: $lupa--orange-soft; //COLOR DE LUPA
}
.bg-lupa-gray-light-blue {
  background-color: $lupa--gray-light-blue;
  border-color: $lupa--gray-light-blue;
}
