/*=============================================
=            Header            =
=============================================*/
header {
  &.stick {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;

    width: 100%;

    animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;

    background-color: $white;
    .header-search {
      display: none;
    }
  }
  .header-search {
    width: 100%;

    form {
      position: relative;

      box-shadow: 0 1px 5px rgba($black, 0.1);
      input {
        font-size: 18px;

        width: 100%;
        padding: 8px 30px 8px 55px;

        color: $black--nine;
        border: none;
        border-radius: 5px;
        background-color: $white;

        ::placeholder {
          opacity: 1; /* Firefox */

          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $grey--twentyThree;
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $grey--twentyThree;
        }

        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: $grey--twentyThree;
        }
      }
      svg {
        position: absolute;
        top: 50%;
        left: 15px;

        transform: translateY(-50%);
      }
    }
  }

  .header-menu-trigger {
    padding: 0;
    border: none;
    background: none;
    &:focus {
      outline: none;
    }
  }
}

@media (min-width:600px) {
  .header-logo{
    width: 250px;
  }
  
}
/* offcanvas menu */

.profile-card {
  padding: 0;
  padding-bottom: 1rem;
  font-size: 0.9rem;
  &__image {
    padding: 2rem 3rem 1rem 3rem;
    img {
      border-radius: 50%;
    }
  }
  &__content {
    p {
      margin-bottom: 0.5rem;
      font-weight: 500;

      color: $black--four;
      span {
        font-size: 14px;
        font-weight: 400;

        display: block;
      }
    }
  }
}

.offcanvas-bg {
  display: block;
  background-color: rgba($black, 0.3);
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 500;
  visibility: hidden;
  &.active {
    visibility: visible;
  }
  .offcanvas-menu {
    position: fixed;
    z-index: 9999;
    //top: 60px;
    right: 0;

    visibility: hidden;

    box-sizing: border-box;
    overflow-y: scroll;
    overflow-x: hidden;

    width: 280px;
    max-width: 70%;
    height: 100%;
    margin-left: auto;
    padding: 1rem;
    padding-top: 0.3rem;

    transition: $transition--medium;
    transform: translateX(100%);

    background-color: #f1f1f1;
    background-image: linear-gradient(to bottom, #f1f1f1 0%, #f2f8ff 100%);
    box-shadow: -5px 0 15px rgba($black, 0.16);

    &.active {
      visibility: visible;
      transform: translateX(0);
    }
  }
}

.offcanvas-navigation {
  overflow-y: scroll;
  font-size: 1.3rem;
  margin: 0 -40px;
  padding: 0 40px;

  li {
    display: flex;
    align-items: center;
    justify-content: space-around;

    padding: 8px 10px 8px 30px;

    border-bottom: 2px solid $white;

    &:nth-child(1) {
      border-top: 2px solid $white;
    }

    span {
      display: block;

      margin-right: 15px;

      svg,
      img {
        width: 20px;
        height: 20px;
      }
    }

    a {
      display: block;

      width: 100%;

      color: $black--four;
    }
  }
}

/* search keyword */

.search-keyword-area {
  display: none;
  &.active {
    display: block;
  }
}

.search-keyword-header {
  line-height: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;
  &__title {
    font-size: 14px;

    flex-basis: 80%;
  }

  &__gl-switcher {
    flex-basis: 20%;
    button {
      padding: 0;

      border: none;
      background: none;
    }
  }
}

.search-keywords {
  margin-bottom: -10px;
  li {
    display: inline-block;

    margin-right: 10px;
    margin-bottom: 10px;
    a {
      font-size: 13px;
      line-height: 1.3;

      display: block;

      padding: 5px 15px;

      color: $black--five;
      border: 1px solid $grey--five;
      background-color: $white;
    }
  }
}

@keyframes fadeInDown {
  0% {
    transform: translate3d(0, -100%, 0);

    opacity: 0;
  }
  100% {
    transform: none;

    opacity: 1;
  }
}
.offcanvas-close-container {
  position: absolute;
  font-size: 1.7rem;
  top: 0.2rem;
  left: 0.7rem;
}
/*=====  End of Header  ======*/
.aeko-footer {
  height: 2.3rem;
  background: #373737;
  position: absolute;
  left: 0;
  right: 0;
  /*   bottom: 0; */
  top: calc(100% - 2.3rem);
  /*   &::before {
    content: "Version 2.5.6";
    position: absolute;
    top: -15px;
    right: 5px;
    font-size: 10px;
  } */
  a {
    width: 100%;
    /* padding: 0.5rem 0; */
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  span {
    color: #aaaaaa;
    font-size: 10px;
    line-height: 0.8rem;
  }
}
.aeko-footer-logo {
  margin-left: 3px;
  margin-bottom: 1px;
  height: 0.8rem;
}
.aeko-footer-version {
  content: "Version 2.5.6";
  position: absolute;
  top: -15px;
  right: 5px;
  font-size: 10px;
}
@media (orientation: landscape) {
  .aeko-footer {
    top: auto;
  }
  .offcanvas-navigation-wrapper {
    padding-bottom: 3rem;
  }
}

@media(min-width:600px){
  .header-menu{
    display: flex;
    float: left;
    margin-left: 47%;   
     li{
       margin-left: 30%;
       margin-top: 15px;
     }
  }
}
@media(max-width:600px){
  .header-menu{
    display: none;
  }
}