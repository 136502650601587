/*=============================================
=            Product Card            =
=============================================*/

.product-card {

  .product-card__img {
    height: 160px;
    box-sizing: border-box;
    overflow: hidden;
  }

  .icon {
    position: absolute;
    top: 0;
    right: 0;

    width: 30px;
    height: 30px;
    padding: 0;

    border: none;
    background: none;
    &:hover,
    &.active {
      svg {
        * {
          fill: #007cba;
        }
      }
      &.inactive {
        svg {
          * {
            fill: rgba($color: #000000, $alpha: 0);
          }
        }
      }
    }
  }
  .product-card__body {
    padding: 0.9rem;
    
    .product-card__title {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      -webkit-box-orient: vertical;
      font-size: 1rem;
      
    }
    .product-card__price {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1;

      margin-bottom: 10px;

      color: $theme-color--default;

      .main-price {
        font-size: 12px;
        font-weight: 600;

        text-decoration: line-through;

        color: $grey--twentyFive;
      }
    }

    .product-card__stock-buttons {
      .dropdown-toggle::after {
        display: none;
      }
    }
  }
}

.product-card-horizontal {
  
  .icon {
    position: absolute;
    top: 0;
    left: 0;

    width: 30px;
    height: 30px;
    padding: 0;

    border: none;
    background: none;

    &:hover,
    &.active {
      svg {
        * {
          fill: #007cba;
        }
      }
      &.inactive {
        svg {
          * {
            fill: rgba($color: #000000, $alpha: 0);
          }
        }
      }
    }
  }
  .product-card-horizontal__img {
    max-height: 145px;
    width: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    
  }

  .product-card__title {
    margin-left: 5px; //mueve el titulo y la descripcion a la derecha
    
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
    font-size: 1rem;
    line-height: normal;
  }

  .product-card__price {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;

    text-align: right;

    margin-bottom: 5px;
    margin-top: 5px;

    color: $theme-color--default;

    .main-price {
      font-size: 12px;
      font-weight: 600;

      text-decoration: line-through;

      color: $grey--twentyFive;
    }
  }
}
.stock-button-group {
  display: flex;
  flex-direction: row;
  background-color: inherit;
  justify-content: end;
  margin-right: 10px;
}
.stock-button {
  background-color: inherit;
  border-color: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: #333;
}
.stock-button:last-child {
  padding-right: 0;
}
.stock-button:first-child {
  padding-left: 0;
}
.stock-button-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: rgb(255, 255, 255);
  margin: 0;
  margin-left: 3px;
  border-radius: 2px;
  width: 80px;
  height: 20px;
}
.add-product-button {
  background-color: #ffffff !important;  // COLOR BOTON
  border: 1px solid #000000;   // COLOR
  color: black;
  font-size: 1rem;
  padding: 4px 6px;
  border-radius: 3px;
  margin: 0px 0.1rem 0.2rem 0.3rem;
  display: flex;
}
.carrito-product-button {
  background-color: white;
  border: 1px solid #000000;
  width: 36px;
  display: flex;
  color: black;
  justify-content: center;
  align-items: center;
  margin-left: 0.1rem !important;
  padding: 0;
  border-radius: 3px;
  margin: 3px 0;
}
.carrito-product-button-icon {
  font-size: 1.2rem;
  font-weight: 400;
  // color:black;
}

.modal-wrapper {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  box-sizing: border-box;
}
.modal-width-custom {
  margin: 0px !important;
  height: 100%;
}
.modal-width-custom > div {
  border: 0px solid black;
  border-radius: 0;
}
.product-detail-modal-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 1rem;
}
.product-detail-modal-arrow-container {
  background-color: $theme-color--default; //COLOR DEL CIRCULO DE LA FLECHA
  color: #fff; //COLOR FONDO DE FLECHA
  font-size: 25px;
  font-weight: bold;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 4px #77777755, 3px 3px 7px #99999988;
}
.product-detail-modal-body {
  height: 100vh;
  overflow-y: scroll;
  padding: 0px;
}
.product-card-body {
  padding: 0;
  padding-left: 5px;
}
.product-card-body-title-wrapper {
  line-height: 1;
  margin-bottom: 0;
}
.product-card-body-title {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1;
  text-align: right;
  margin-bottom: 5px;
  margin-top: 5px;
  color: #193498; //ACA CAMBIA COLOR TITULO Y PRECIO PRODUCTOS LISTA DE ENTRADA
}
.product-card-body-title:first-child {
  margin-left:5px; //margen del titulo que lo corre a la derecha
  float: left;
}
/*=====  DESKTOP STYLES  ======*/

.product-card-body-title-desk {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1;
  text-align: left;
  margin-bottom: 5px;
  margin-top: 15px;
  color: #007cba;

}

.product-card-body-title-desk-price {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1;
  text-align: right;
  float: right;
  margin-bottom: 5px;
  margin-top: 15px;
  color: #007cba;

}
.product-card-cont{
  padding: 10px;
  align-items: center;
  justify-content: center;
}
.btn-bottom {
  position: absolute;
  bottom: 0;
  
}
.add-product-button-desk {
  background-color: #007cba;
  border: 1px solid #007cba;
  font-size: 1rem;
  padding: 4px 6px;
  border-radius: 3px;
  margin-left: 5%;
}
.product-card-img-desk {
    height: 150px;
    width: 150px;
    box-sizing: border-box;
    overflow: hidden;
  }

.product-card-title-desk {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
    font-size: 1rem;
    line-height: normal;
  }

  .img-modal-desk{
      width: 350px;
      height: 350px;  
      margin-left: 23%; 
      padding: 10px;
  }

  .detail-desc-container{
    overflow-y: auto;
    scroll-behavior: smooth;
    height: 65px;
  }
.product-detail-modal-close-container {
  color: $theme-color--default;
  font-size: 30px;
  font-weight: bold;
  position: fixed;
    top: 20px;
    right: 30px;
    color: #FFF;
    cursor: pointer;
  
}
// @media(min-width:600px){
// .stock-button{
//    span{
//      width: 25px;
//    }
// }

// }

/*=====  End of Product Card  ======*/
