/* contact content */

.contact-page-content {
    &__image {
        height: 200px;
    }
    &__text {
        h3 {
            font-size: 18px;

            margin-bottom: 10px;
        }
    }

    &__text-block {
        h4 {
            font-size: 14px;

            margin-bottom: 5px;
        }
    }
}
@media(min-width:600px){
    .contact-page-content {
        
    &__image {
        height: 200px;
        max-width: 50vw;
        justify-content: center;
    }
    &__text {
        h3 {
            font-size: 18px;

            margin-bottom: 10px;
        }
    }

    &__text-block {
        h4 {
            font-size: 14px;

            margin-bottom: 5px;
        }
    }
}

}

.map-wrapper {
    iframe {
        width: 100%;
        height: 300px;

        border: none;
    }
}

.contact-page-content__text-block h4 {
    color: #999;
}
.contact-page-content__text-block p {   
    font-size: 18px;
    line-height: 1.25;
}