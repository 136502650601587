/*=============================================
=            cart            =
=============================================*/
.cart-product-area {
  padding-bottom: 3rem;
}
.cart-plus-minus {
  position: relative;

  display: inline-block;

  width: 90px;
  height: 30px;
  padding: 0;
  .qtybutton {
    font-size: 16px;
    line-height: 30px;

    position: absolute;

    float: inherit;

    width: 20px;
    margin: 0;

    cursor: pointer;
    transition: all 0.3s ease 0s;
    text-align: center;

    color: $black--five;
    border: none;
    background: none;
  }
  .dec.qtybutton {
    top: 0;
    left: 0;

    height: 30px;

    border-right: 1px solid $grey--eight;
  }
  .inc.qtybutton {
    top: 0;
    right: 0;

    height: 30px;

    border-left: 1px solid $grey--eight;
  }
  input.cart-plus-minus-box {
    font-size: 14px;

    float: left;

    width: 90px;
    height: 30px;
    margin: 0;
    padding: 0;

    text-align: center;

    color: $black--five;
    border: 1px solid $grey--eight;
    background: transparent none repeat scroll 0 0;
  }
}

.discount-code-wrapper {
  padding: 30px 0;

  border-top: 1px solid $grey--seven;
  border-bottom: 1px solid $grey--seven;
  border-radius: 5px;

  .title-wrap {
    position: relative;
    &::before {
      position: absolute;
      z-index: 1;
      top: 10px;
      left: 0;

      width: 100%;
      height: 1px;

      content: "";
      transition: all 0.4s ease 0s;
    }
    h4.cart-bottom-title {
      font-size: 18px;
      font-weight: 500;

      display: inline-block;

      margin: 0;
      padding-right: 18px;
    }
  }

  .discount-code {
    margin: 21px 0 0;
    p {
      margin: 0 0 15px;
    }
    form {
      input {
        width: 100%;
        height: 40px;
        margin-bottom: 10px;
        padding-left: 10px;

        border: 1px solid $grey--seven;
      }
      button.cart-btn-2 {
        font-size: 14px;
        font-weight: 500;

        padding: 13px 30px 12px;

        cursor: pointer;
        transition: all 0.3s ease 0s;
        text-transform: uppercase;

        color: $white;
        border: medium none;
        background-color: $theme-color--default;
      }
    }
  }
}

.grand-total {
  background-color: rgba($color: rgb(247, 248, 249), $alpha: 0.9);
  width: 100%;
  position: fixed; /* 
    right: 1rem;
    bottom: 2.5rem; */
  bottom: 40px;
  padding-bottom: 1.5rem;

  /*  border: 1px solid $grey--twelve;
  border-bottom: 1px solid $grey--seven; */

  .title-wrap {
    position: relative;
    &::before {
      position: absolute;
      z-index: 1;
      top: 10px;
      left: 0;

      width: 100%;
      height: 1px;

      content: "";
      transition: all 0.4s ease 0s;
    }
    h4.cart-bottom-title {
      font-size: 18px;
      font-weight: 500;

      display: inline-block;

      margin: 0;
      padding-right: 18px;
    }
    .section-bg-gary-cart {
      position: relative;
      z-index: 9;
    }
  }
  h5 {
    font-size: 14px;

    margin: 36px 0 27px;
    span {
      font-size: 18px;
      font-weight: 500;

      float: right;
    }
  }
  .total-shipping {
    margin: 0 0 27px;
    padding: 28px 0;

    border-top: 1px solid $grey--seven;
    border-bottom: 1px solid $grey--seven;
    h5 {
      font-size: 14px;

      margin: 0;
    }
    ul {
      padding: 19px 0 0 0;
      li {
        margin: 0 0 6px;

        list-style: outside none none;

        &:last-child {
          margin: 0 0 0;
        }

        span {
          float: right;
        }
      }
    }
  }
  h4.grand-total-title {
    font-size: 20px;
    font-weight: 500;
    padding-top: 7px;
    padding-bottom: 7px;
    span {
      float: right;
    }
  } /* 
    a {
        font-size: 14px;
        font-weight: 500;
        line-height: 1;

        display: block;

        padding: 18px 10px 17px;

        text-align: center;
        text-transform: uppercase;

        color: $white;
        background-color: $theme-color--default;
    } */
  a {
    button {
      text-align: center;
      text-transform: uppercase;
      font-size: 1rem;
      // color: $white;
      // background-color: $theme-color--default;
      color: black;
      background-color: white;
    }
  }
}

.empty-cart-modal-content {
  border-radius: 0 0 8px 8px;
  border: none;
  padding: 1.6rem 1rem;
}
.empty-cart-modal-content > div:first-child {
  font-size: 18px;
  text-align: center;
  margin-bottom: 1.3rem;
}
.empty-cart-modal-content > div:last-child {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
}
.empty-cart-modal-content div:last-child div:first-child {
  font-size: 14px;
  padding: 3px 7px;
  margin-right: 1rem;
}
.empty-cart-modal-content div:last-child div:last-child {
  background-color: rgb(255, 93, 93);
  border-color: rgb(255, 93, 93);
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 3px 7px;
  margin-right: 1rem;
}
@media(max-width:600px){
.empty-cart-modal-dialog {
  margin: 0;
}
}
/*=====  Cart Desktop  ======*/
@media (min-width:600px){
  .empty-cart-modal-content {
  border-radius: 8px;
  border: none;
  padding: 1.6rem 1rem;
  justify-content: center;
}
.empty-cart-modal-content > div:first-child {
  font-size: 18px;
  text-align: center;
  margin-bottom: 1.3rem;
}
  .body-wrapper{
    width: 70vw;
    margin-left: 15%;
  }
  .grand-total{
    width: 70vw;
    a{
      justify-content: flex-end !important;
      padding-top: 10px;
    }
  }
}
/*=====  End of cart  ======*/
